@import "@styles/theme", "@styles/mixins";

.mainSlide {
    position: relative;

    .svg1 {
        &:before {
            position: absolute;
            content: " ";
            display: block;
            top: 5%;
            left: 3.5%;
            width: 30%;
            height: 30%;
            background-image: url(../../../public/images/ui/selo-mais-vendido.svg);
            z-index: 9;
            background-repeat: no-repeat;
            background-size: contain
        }
    }

    .svg2 {
        &:before {
            position: absolute;
            content: " ";
            display: block;
            top: 15.5%;
            right: 6.5%;
            width: 40%;
            height: 35%;
            background-image: url(../../../public/images/ui/nova-fiat-strada.svg);
            z-index: 9;
            background-repeat: no-repeat;
            background-size: contain
        }
    }
}

.pageHeading {
    position: absolute;
    width: 100%;
    z-index: -1
}

.container {
    width: 100%;
    height: 100vh;
    min-height: 500px !important;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: grab;
    margin-top: 52px;

    &:active {
        cursor: grabbing
    }

    .scrollImage {
        position: absolute;
        z-index: 1;
        bottom: 96px;
        left: 86px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 16px;
    }
}

.slides {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .fullBgGradient {
        position: absolute;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(#00000047, transparent)
    }

    &.centered {
        align-items: center;
        justify-content: center
    }

    .content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
        height: 100%;

        .wrapLogo {
            width: 100%;
            min-height: 71px;
            margin-top: 6%;
            display: flex;
            justify-content: center;

            .boxLogo {
                width: 55%;
                height: 70%;
                margin-left: -4%;

                svg {
                    width: 100%;
                    height: 100%
                }
            }
        }

        .descriptionContent {
            width: 100%;
            max-width: 600px;
            height: 110px;
            pointer-events: none;

            @media (min-width:1600px) {
                left: clamp(20px, 13vw, 130px)
            }

            @include xxl {
                left: clamp(40px, 13vw, 223px)
            }
        }

        .descriptionLayoutTwo {
            position: absolute;
            bottom: unset;
            margin: 0 !important;
            padding: 0;
            left: clamp(20px, 15vw, 225px);
            bottom: 60%;
            width: 30%;

            @media (max-width:1400px) {
                bottom: 60%;
                width: 30%
            }

            @media (max-width:1600px) {
                left: clamp(20px, 13vw, 250px);
                bottom: 60%
            }

            @media (min-width:1679px) {
                width: 35%
            }

            @media (min-width:1920px) {
                left: clamp(40px, 14vw, 280px)
            }

            .line {
                height: 5px;
                background-color: $secondary;
                width: 65%
            }

            .tltBottom {
                font-family: $font-family-extra-bold;
                font-size: clamp(32px, 2vw, 5rem);
                text-transform: uppercase;
                line-height: 3rem;
                margin-bottom: 1rem;
                color: $text-light;
                max-width: 80%;
                text-wrap: balance;

                @media (min-width:1679px) {
                    margin-bottom: 3rem;
                    max-width: 60%;
                    margin-bottom: 1rem
                }
            }

            p {
                margin-top: 1rem;
                max-width: 100%;
                font-size: clamp(1rem, 1.01vw, 1.375rem) !important
            }

            img {
                width: 20%
            }
        }
    }
}

.logoContent {
    height: 11vh;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;

    .logoHero {
        margin-top: 15px
    }
}