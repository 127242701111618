@import '@styles/theme', '@styles/mixins', '@styles/animations';

.sectionContainer {
  width: 100%;
  display: flex;

  position: relative;
  justify-content: space-between;
  align-items: center;

  aspect-ratio: 2.783;
  background-image: url('/images/banner-more-sold/background/background-desktop.webp');
  background-size: cover;
  background-repeat: no-repeat;

  padding: 0 !important;

  @include mobile {
    background-position: top;
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    aspect-ratio: 0.687;

    background-image: url('/images/banner-more-sold/background/background-mobile.webp');
  }


  .lettering {
    padding-left: 10.18vw;

    display: flex;
    gap: 16px;
    flex-direction: column;


    .title {
      font-size: 42px;
      font-weight: 800;
      line-height: 44px;
      color: #212652;
      text-transform: uppercase;

      @include mobile {
        font-size: 23px;
        line-height: 27px;
      }

    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #212652;

      @include mobile {
        font-size: 14px;
        line-height: 18px;
        margin-top: 16px;
      }
    }


    .ctaButton {
      background-color: #FF1430 !important;
      max-width: 177px;
      height: 50px;

      clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
      overflow: hidden;

      >div {
        background-color: #FF1430 !important;

        max-width: 177px;
        height: 50px;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        p {
          text-align: center;
          text-transform: uppercase;

          @include mobile {
            font-size: 11px;
            max-width: 162px;

          }

        }


        &::before {
          display: none;
        }

        svg {
          display: none;
        }

      }


      @include mobile {
        margin-top: 32px;
        align-self: center;
      }
    }


    @include mobile {
      margin-bottom: 40px;
      gap: 0;
      padding: 0;
    }
  }


  .cars {
    margin-right: 0.898204vw;
    margin-top: 3.80vh;
    margin-bottom: 7.87vh;
    aspect-ratio: 2.112676056338028;

    max-width: 750px;
    max-height: 355px;

   @include mobile {
      max-width: unset;
      max-height: unset;


      width: 100%;
      flex-shrink: 0;
      aspect-ratio: 340/273;
      margin: 0;
      padding: 0 7px 0 24px;
    }
  }
}